<template>
  <div>
    <!-- ********************** No2 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg2'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval2">{{ this.dval2.title }}</h4>
        <CRow v-if="this.flgset==='flg2'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===1">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart2 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval2"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No3 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg2'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval3">{{ this.dval3.title }}</h4>
        <CRow v-if="this.flgset==='flg2'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===2">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart3 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval3"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No4 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg2'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval4">{{ this.dval4.title }}</h4>
        <CRow v-if="this.flgset==='flg2'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===3">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart4 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval4"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No5 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg2'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval5">{{ this.dval5.title }}</h4>
        <CRow v-if="this.flgset==='flg2'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===4">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart5 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval5"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No6 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg2'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval6">{{ this.dval6.title }}</h4>
        <CRow v-if="this.flgset==='flg2'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===5">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart6 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval6"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No7 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg2'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval7">{{ this.dval7.title }}</h4>
        <CRow v-if="this.flgset==='flg2'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===6">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart7 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval7"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No8 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg2'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval8">{{ this.dval8.title }}</h4>
        <CRow v-if="this.flgset==='flg2'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===7">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart8 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval8"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No9 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg3'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval9">{{ this.dval9.title }}</h4>
        <CRow v-if="this.flgset==='flg3'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===8">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart9 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval9"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No10 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg3'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval10">{{ this.dval10.title }}</h4>
        <CRow v-if="this.flgset==='flg3'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===9">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart10 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval10"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No11 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg3'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval11">{{ this.dval11.title }}</h4>
        <CRow v-if="this.flgset==='flg3'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===10">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart11 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval11"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No12 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg3'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval12">{{ this.dval12.title }}</h4>
        <CRow v-if="this.flgset==='flg3'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===11">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart12 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval12"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No13 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg3'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval13">{{ this.dval13.title }}</h4>
        <CRow v-if="this.flgset==='flg3'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===12">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart13 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval13"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No14 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg3'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval14">{{ this.dval14.title }}</h4>
        <CRow v-if="this.flgset==='flg3'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===13">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart14 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval14"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No15 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg3'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval15">{{ this.dval15.title }}</h4>
        <CRow v-if="this.flgset==='flg3'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===14">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart15 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval15"/>
      </CCardBody>
    </CCard>
    <!-- ********************** No16 ********************** -->
    <CCard v-if="this.flgset==='flg1' || this.flgset==='flg3'">
      <CCardHeader>
        <h4 class="card-title mb-0" v-bind:dval="this.dval16">{{ this.dval16.title }}</h4>
        <CRow v-if="this.flgset==='flg3'">
          <CCol>
            <br>
            <div v-for="item in this.items" v-bind:key="item.id">
              <div v-if="item.id===15">
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">{{ item.text }}</div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <DashboardChart16 style="height:300px;margin-top:10px;" v-bind:val="this.val" :dval="this.dval16"/>
      </CCardBody>
    </CCard>

  </div>
</template>

<script>
// import DashboardChart1 from './DashboardChart1'
import DashboardChart2 from './DashboardChart2'
import DashboardChart3 from './DashboardChart3'
import DashboardChart4 from './DashboardChart4'
import DashboardChart5 from './DashboardChart5'
import DashboardChart6 from './DashboardChart6'
import DashboardChart7 from './DashboardChart7'
import DashboardChart8 from './DashboardChart8'
import DashboardChart9 from './DashboardChart9'
import DashboardChart10 from './DashboardChart10'
import DashboardChart11 from './DashboardChart11'
import DashboardChart12 from './DashboardChart12'
import DashboardChart13 from './DashboardChart13'
import DashboardChart14 from './DashboardChart14'
import DashboardChart15 from './DashboardChart15'
import DashboardChart16 from './DashboardChart16'

export default {
  name: 'displayCharts',
  components: {
    // DashboardChart1,
    DashboardChart2,
    DashboardChart3,
    DashboardChart4,
    DashboardChart5,
    DashboardChart6,
    DashboardChart7,
    DashboardChart8,
    DashboardChart9,
    DashboardChart10,
    DashboardChart11,
    DashboardChart12,
    DashboardChart13,
    DashboardChart14,
    DashboardChart15,
    DashboardChart16,
  },
  props: {
    'flgset': {
      type: String
    },
    'val': {
      type: Array
    },
    'items': {
      type: Array
    },
    'dval1': {
      type: Object
    },
    'dval2': {
      type: Object
    },
    'dval3': {
      type: Object
    },
    'dval4': {
      type: Object
    },
    'dval5': {
      type: Object
    },
    'dval6': {
      type: Object
    },
    'dval7': {
      type: Object
    },
    'dval8': {
      type: Object
    },
    'dval9': {
      type: Object
    },
    'dval10': {
      type: Object
    },
    'dval11': {
      type: Object
    },
    'dval12': {
      type: Object
    },
    'dval13': {
      type: Object
    },
    'dval14': {
      type: Object
    },
    'dval15': {
      type: Object
    },
    'dval16': {
      type: Object
    }
  }
}
</script>
