<template>
  <div>
    <h4>下記はサンプルデータです</h4>
    <CRow v-if="this.flgValue==='flg1'">
      <CCol sm="6" lg="3">
        <CWidgetProgress v-bind:footer="'100回まであと' + moretime + '回'">
          <div class="h4 m-0">{{ numbertime }}</div>
          <div class="card-header-actions">
            <a
              href="https://www.kenketsu.jp/Login?startURL=%2F"
              class="card-header-action position-absolute"
              style="right:5px; top:5px"
              target="_blank"
            >
              <small class="text-muted">献血予約サイトへ</small>
            </a>
          </div>
          <div>献血回数</div>
          <CProgress
            color="gradient-success"
            v-bind:value="Number(numbertime)"
            class="progress-xs my-3 mb-0"
          />
        </CWidgetProgress>
        <CWidgetIcon
          v-bind:header="lastdate"
          text="前回の献血日"
          color="gradient-warning"
          :icon-padding="false"
        >
          <CIcon name="cil-check" class="mx-4" width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol lg="8">
        <!-- ********************** No1 ********************** -->
        <CCard>
          <CCardHeader>
            <h4 class="card-title mb-0">(1) 血圧・脈拍</h4>
            <CRow>
              <CCol>
                <br>
                <div class="small text-muted"><strong>説明</strong></div>
                <div class="text-muted">一般的には最高血圧が140 mmHg未満、最低血圧が90 mmHg未満</div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <DashboardChart1 style="height:200px;margin-top:10px;" v-bind:val="apidata" :dval="dval1"/>
          </CCardBody>
        </CCard>
     </CCol>
    </CRow>

    <CRow v-if="this.flgValue==='flg1'">
      <CCardGroup columns class="card-columns cols-4">
        <display-charts v-bind:flgset="this.flgValue"
                              :val="apidata"
                              :dval2="dval2"
                              :dval3="dval3"
                              :dval4="dval4"
                              :dval5="dval5"
                              :dval6="dval6"
                              :dval7="dval7"
                              :dval8="dval8"
                              :dval9="dval9"
                              :dval10="dval10"
                              :dval11="dval11"
                              :dval12="dval12"
                              :dval13="dval13"
                              :dval14="dval14"
                              :dval15="dval15"
                              :dval16="dval16"
                              >
        </display-charts>
      </CCardGroup>
    </CRow>
    <!-- display2 -->
    <CRow v-if="this.flgValue==='flg2'">
      <display-charts v-bind:flgset="this.flgValue"
                            :val="apidata"
                            :items="items"
                            :dval2="dval2"
                            :dval3="dval3"
                            :dval4="dval4"
                            :dval5="dval5"
                            :dval6="dval6"
                            :dval7="dval7"
                            :dval8="dval8">
      </display-charts>
    </CRow>
    <!-- display3 -->
    <CRow v-if="this.flgValue==='flg3'">
      <display-charts v-bind:flgset="this.flgValue"
                            :val="apidata"
                            :items="items"
                            :dval9="dval9"
                            :dval10="dval10"
                            :dval11="dval11"
                            :dval12="dval12"
                            :dval13="dval13"
                            :dval14="dval14"
                            :dval15="dval15"
                            :dval16="dval16">
      </display-charts>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import displayCharts from './displayCharts.vue'
import DashboardChart1 from './DashboardChart1'


export default {
  name: 'getsetData',
  components: {
    displayCharts,
    DashboardChart1
  },
  props: {
    'flgValue': {
      type: String
    }
  },
  data () {
    return {
      apidata: [],
      items: [],
      numbertime: '',
      lastdate: '',
      moretime: '',
      dval1: {},
      dval2: {},
      dval3: {},
      dval4: {},
      dval5: {},
      dval6: {},
      dval7: {},
      dval8: {},
      dval9: {},
      dval10: {},
      dval11: {},
      dval12: {},
      dval13: {},
      dval14: {},
      dval15: {},
      dval16: {},
    }
  },
  created () {

    const bookid = '12rpAAtjxDU9hJRvNOyl9S5aEitOWkYuDBhsh377R5Uk';
    const sheetname = 'blooddonation';
    const googleapi = 'AIzaSyBTNc9rXA17QLC0AMKQICSak9bmSLPGBZQ';
    const apiurl = 'https://sheets.googleapis.com/v4/spreadsheets/' + bookid + '/values/' + sheetname + '?key=' + googleapi;

    axios.get(apiurl).then(response => {
      this.apidata = response.data.values
      this.makeInfo()      // ヘッダー情報・説明テキストの取得設定
    })

    this.dval1 = {
      label1: '血圧(最高)', colum1: 3,
      label2: '血圧(最低)', colum2: 4,
      label3: '脈拍', colum3: 5,
      maxGraph: 180,
      minGraph: 30
    }
    this.dval2 = {
      title:'(2) ALT(GPT)', label: 'ALT(GPT)', colum: 6, maxValue: 49, minValue: 8, maxGraph: 70, minGraph: 0
    }
    this.dval3 = {
      title:'(3) γ-GTP', label: 'γ-GTP', colum: 7, maxValue: 68, minValue: 9, maxGraph: 80, minGraph: 0
    }
    this.dval4 = {
      title:'(4) 総蛋白 TP', label: '総蛋白 TP', colum: 8, maxValue: 8.2, minValue: 6.6, maxGraph: 10.0, minGraph: 4.0
    }
    this.dval5 = {
      title:'(5) アルブミン ALB', label: 'アルブミン ALB', colum: 9, maxValue: 5.1, minValue: 4.0, maxGraph: 6.0, minGraph: 3.0
    }
    this.dval6 = {
      title:'(6) アルブミン対グロブリン比 A/G', label: 'アルブミン対グロブリン比 A/G', colum: 10, maxValue: 2.1, minValue: 1.3, maxGraph: 3.0, minGraph: 0.0
    }
    this.dval7 = {
      title:'(7) コレステロール CHOL', label: 'コレステロール CHOL', colum: 11, maxValue: 259, minValue: 140, maxGraph: 300, minGraph: 50
    }
    this.dval8 = {
      title:'(8) グリコアルブミン GA', label: 'グリコアルブミン GA', colum: 12, maxValue: 16.5, minValue: 0.1, maxGraph: 20.0, minGraph: 0.0
    }
    this.dval9 = {
      title:'(9) 赤血球数 RBC', label: '赤血球数 RBC', colum: 13, maxValue: 560, minValue: 418, maxGraph: 700, minGraph: 200
    }
    this.dval10 = {
      title:'(10) ヘモグロビン量 Hb', label: 'ヘモグロビン量 Hb', colum: 14, maxValue: 17.0, minValue: 12.7, maxGraph: 20.0, minGraph: 5.0
    }
    this.dval11 = {
      title:'(11) ヘマトクリット値 Ht', label: 'ヘマトクリット値 Ht', colum: 15, maxValue: 55.0, minValue: 38.8, maxGraph: 65.0, minGraph: 20.0
    }
    this.dval12 = {
      title:'(12) 平均赤血球容積 MCV', label: '平均赤血球容積 MCV', colum: 16, maxValue: 99.5, minValue: 83.0, maxGraph: 120.0, minGraph: 50.0
    }
    this.dval13 = {
      title:'(13) 平均赤血球ヘモグロビン量 MCH', label: '平均赤血球ヘモグロビン量 MCH', colum: 17, maxValue: 33.5, minValue: 26.8, maxGraph: 45.0, minGraph: 10.0
    }
    this.dval14 = {
      title:'(14) 平均赤血球ヘモグロビン濃度 MCHC', label: '平均赤血球ヘモグロビン濃度 MCHC', colum: 18, maxValue: 35.2, minValue: 31.7, maxGraph: 45.0, minGraph: 20.0
    }
    this.dval15 = {
      title:'(15) 白血球数 WBC', label: '白血球数 WBC', colum: 19, maxValue: 89, minValue: 38, maxGraph: 100, minGraph: 0
    }
    this.dval16 = {
      title:'(16) 血小板数 PLT', label: '血小板数 PLT', colum: 20, maxValue: 36.5, minValue: 17.0, maxGraph: 45.0, minGraph: 0.0
    }

  },
  methods: {
    // // ヘッダー情報・説明テキストの取得設定
    makeInfo () {
      for (var i = 1; i <= this.apidata.length; i++) {
        // 説明テキスト設定
        if (i === 4) {
          var k = 1
          for (var j = 0; j <= 20; j++) {
            if (j > 5) {
              var additem = {
                id: k,
                text: this.apidata[3][j]
              }
              this.items.push(additem)
              k = k + 1
            }
          }
        }
        // 最終行の回数と日付の設定
        if (i === this.apidata.length) {
          this.numbertime = this.apidata[i-1][0]
          this.lastdate = this.apidata[i-1][1]
          this.moretime = 100 - this.numbertime
        }
      }
    }
  }
}
</script>
