<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="defaultLabels"
  />
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'DashboardChart1',
  components: {
    CChartLine
  },
  props: {
    'val': {
      type: Array
    },
    'dval': {
      type: Object
    }
  },
  watch: {
    val(val) {
      this.makeGraph()
    }
  },
  data() {
    return {
      defaultLabels: [],
      defaultDatasets: []
    }
  },
  methods: {
    makeGraph() {

      // ラベル作成
      for (var i = 0; i < this.val.length; i++) {
        if (i > 4) {
          this.defaultLabels.push(this.val[i][1])
        }
      }

      const data1 = []
      const data2 = []
      const data3 = []

      // データ作成
      for (var i = 0; i < this.val.length; i++) {
        if (i > 4) {
          data1.push(this.val[i][this.dval.colum1])
          data2.push(this.val[i][this.dval.colum2])
          data3.push(this.val[i][this.dval.colum3])
        }
      }

      // 値セット
      this.defaultDatasets = [
        {
          label: this.dval.label1,
          backgroundColor: 'transparent',
          borderColor: 'red',
          pointHoverBackgroundColor: 'red',
          borderWidth: 1,
          data: data1
        },
        {
          label: this.dval.label2,
          backgroundColor: 'transparent',
          borderColor: 'blue',
          pointHoverBackgroundColor: 'blue',
          borderWidth: 1,
          data: data2
        },
        {
          label: this.dval.label3,
          backgroundColor: 'transparent',
          borderColor: 'green',
          pointHoverBackgroundColor: 'green',
          borderWidth: 1,
          borderDash: [8, 5],
          data: data3
        }
      ]

    }

  },
  computed: {
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              maxTicksLimit: 5,
              max: this.dval.maxGraph,
              min: this.dval.minGraph
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 1,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  }
}
</script>
