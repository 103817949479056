<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="defaultLabels"
  />
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'

export default {
  name: 'DashboardChart7',
  components: {
    CChartLine
  },
  props: {
    'val': {
      type: Array
    },
    'dval': {
      type: Object
    }
  },
  watch: {
    val(val) {
      this.makeGraph()
    }
  },
  data() {
    return {
      defaultLabels: [],
      defaultDatasets: []
    }
  },
  methods: {
    makeGraph() {

      for (var i = 0; i < this.val.length; i++) {
        if (i > 4) {
          this.defaultLabels.push(this.val[i][1])
        }
      }

      const data1 = []
      const data2 = []
      const data3 = []

      for (var i = 0; i < this.val.length; i++) {
        if (i > 4) {
          data1.push(this.val[i][this.dval.colum])
        }
        data2.push(this.dval.maxValue)
        data3.push(this.dval.minValue)
      }

      this.defaultDatasets = [
        {
          label: this.dval.label,
          backgroundColor: 'transparent',
          borderColor: '#20a8d8',
          pointHoverBackgroundColor: '#20a8d8',
          borderWidth: 2,
          data: data1
        },
        {
          label: '基準値：高',
          backgroundColor: 'transparent',
          borderColor: '#f86c6b',
          pointHoverBackgroundColor: '#f86c6b',
          borderWidth: 1,
          borderDash: [8, 5],
          data: data2
        },
        {
          label: '基準値：低',
          backgroundColor: 'transparent',
          borderColor: '#4dbd74',
          pointHoverBackgroundColor: '#4dbd74',
          borderWidth: 1,
          borderDash: [8, 5],
          data: data3
        }
      ]

    }

  },
  computed: {

    defaultOptions () {
      return {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              maxTicksLimit: 5,
              max: this.dval.maxGraph,
              min: this.dval.minGraph
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 1,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }

  }
}
</script>
